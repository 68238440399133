<template>
  <div style="width: 100%; height: 100%;"></div>
</template>

<script>
import { DualAxes } from "@antv/g2plot";

export default {
  name: "G2Plot",

  props: {
    type: {
      type: String
    },
    option: {
      type: Object
    }
  },

  data() {
    return {
      plot: null
    };
  },

  methods: {
    initChart() {
      this.plot && this.plot.destroy();

      this.option.width = this.$el.clientWidth;
      this.option.height = this.$el.clientHeight;

      switch (this.type) {
        case "DualAxes":
          this.plot = new DualAxes(this.$el, this.option);
          break;
      }

      this.plot.render();
    }
  },

  mounted() {
    this.initChart();
  },

  beforeDestroy() {
    this.plot && this.plot.destroy();
  }
};
</script>

<style></style>
