<template>
  <div>
    <div class="panel-sub-title">
      <div class="title">
        {{ $t("storeSaleData") }}
      </div>

      <div>
        <el-select class="cycle-select" v-model="shopCycle" v-on:change="getList(1)" :disabled="loading">
          <el-option v-for="item in shopCycleList" :key="item.value" :value="item.value" :label="item.name"></el-option>
        </el-select>
      </div>
    </div>

    <div>
      <el-table :data="shopDataList" v-loading="loading">
        <el-table-column prop="shopName" v-bind:label="$t('franchiseStore')" min-width="50%"></el-table-column>
        <el-table-column prop="amount" v-bind:label="$t('money')" min-width="25%">
          <template slot-scope="scope">
            <div>{{ moneyFormat(scope.row.amount) }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="ratio" v-bind:label="$t('ratio')" min-width="25%"></el-table-column>
        <el-table-column v-bind:label="$t('comparedAmount' + shopCycle)" width="150">
          <template slot-scope="scope">
            <div v-bind:class="scope.row.previousMonthAmount.startsWith('-') ? 'attenuate' : 'increase'">
              {{ scope.row.previousMonthAmount ? moneyFormat(scope.row.previousMonthAmount) : "" }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="previousMonthRatio" v-bind:label="previousRatioColumnName" width="150">
          <template slot-scope="scope">
            <div v-bind:class="scope.row.previousMonthAmount.startsWith('-') ? 'attenuate' : 'increase'">
              {{ scope.row.previousMonthRatio }}
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        layout=" prev, pager, next, sizes, total"
        :total="total"
        :page-size="currentPageSize"
        :page-sizes="[10, 15, 20]"
        :current-page="currentPage"
        @current-change="currentChange"
        @size-change="sizeChange"
        class="common-pagination"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { getSessionStorage } from "@/utils/storage";
import { moneyFormat } from "utils/index";
import { storeData } from "api/panel";
import pagination from "@/mixins/pagination";
export default {
  mixins: [pagination],
  data() {
    return {
      loading: false,
      shopDataList: [],
      shopCycleList: [
        {
          value: "Today",
          name: this.$t("today")
        },
        {
          value: "Yestoday",
          name: this.$t("yestoday")
        },
        {
          value: "CurrentWeek",
          name: this.$t("currentWeek")
        },
        {
          value: "CurrentMonth",
          name: this.$t("currentMonth")
        },
        {
          value: "LastMonth",
          name: this.$t("lastMonth")
        },
        {
          value: "Quarter",
          name: this.$t("quarter")
        },
        {
          value: "Year",
          name: this.$t("year")
        }
      ],
      shopCycle: "Today",
      previousRatioColumnName: this.$t("comparedTheSameDayLastMonth"),
      userInfo: {},
      btnLoading: false
    };
  },
  mounted() {
    this.currentPageSize = 15;
    this.userInfo = JSON.parse(getSessionStorage("userInfo"));
    this.getList();
  },
  methods: {
    moneyFormat,
    getList(pageIndex = this.currentPage, pageSize = this.currentPageSize) {
      let that = this;
      that.currentPage = pageIndex;
      that.currentPageSize = pageSize;
      switch (that.shopCycle) {
        case "CurrentWeek":
          // 本周
          that.previousRatioColumnName = this.$t("comparedTheSameWeekLastMonth");
          break;
        case "CurrentMonth":
          // 本月
          that.previousRatioColumnName = this.$t("comparedLastMonth");
          break;
        case "LastMonth":
          // 上月
          that.previousRatioColumnName = this.$t("comparedTheSameMonthLastMonth");
          break;
        case "Quarter":
          that.previousRatioColumnName = this.$t("comparedTheSameQuarterLastYear");
          break;
        case "Year":
          that.previousRatioColumnName = this.$t("comparedLastYear");
          break;
        default:
          that.previousRatioColumnName = this.$t("comparedTheSameDayLastMonth");
          break;
      }
      that.loading = true;
      storeData({
        statisticsCategory: that.shopCycle,
        pageIndex,
        pageSize
      }).then(response => {
        if (response.statusCode === 200 && response.resultStatus === "Success") {
          console.log(response.result);
          that.shopDataList = response.result;
          that.total = response.totalCount;
        }
        that.loading = false;
      });
    }
  }
};
</script>
