<template>
  <div>
    <div :class="[messageType === 'warning' ? importantClass : nomalClass]">
      <svg-icon icon-class="error" class="notification-icon"></svg-icon>
      <div class="notification-title">
        {{ message.messageTitle }}
      </div>
      <div class="notification-sub-title">
        {{ message.createDate }}
      </div>
      <!-- <div class="notification-message">
        これこれこういう理由で、審査を通過することが出来ませんでした。
        問題点を解消した上で再申請いただくか、問題点が不明な場合はカスタマーサポートまでご連絡ください。
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  props: {
    messageType: {
      type: String,
      default: ""
    },
    message: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      nomalClass: "notification",
      importantClass: "notification warning"
    };
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/mixin.scss";
@import "~@/styles/variables.scss";

.notification {
  padding: 16px 24px 16px 64px;
  position: relative;
  border-radius: 8px;
  cursor: pointer;

  .notification-icon {
    position: absolute;
    left: 24px;
    top: 16px;
  }

  .notification-title {
    color: $color-main-text;
    line-height: 23px;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 10px;
    word-break: break-all;
  }

  .notification-sub-title {
    color: $color-sub-text;
    line-height: 17px;
    font-size: 12px;
  }

  .notification-message {
    margin-top: 10px;
    color: $color-main-text;
    font-size: 14px;
    line-height: 20px;
  }

  &.warning {
    background: #fce1e8;
    margin-top: 20px;
  }

  &.info {
    background: #e7e8ea;
  }
}
</style>
