import http from "../http";

export const manageMessageList = params => {
  return http({
    method: "get",
    url: `/api/message/managelist`,
    params
  });
};
export const messageList = params => {
  return http({
    method: "get",
    url: `/api/message/list`,
    params
  });
};

export const messageListForWeb = params => {
  return http({
    method: "get",
    url: `/api/message/notifications`,
    params
  });
};

export const messageDetail = params => {
  return http({
    method: "get",
    url: `/api/message/detailonedit`,
    params
  });
};

export const sysMessageDetail = params => {
  return http({
    method: "get",
    url: `/api/message/sysdetailonedit`,
    params
  });
};

export const createMessage = data => {
  return http({
    method: "post",
    url: `/api/message/create`,
    data
  });
};

export const createSystemMessage = data => {
  return http({
    method: "post",
    url: `/api/message/createsystem`,
    data
  });
};

export const updateMessage = data => {
  return http({
    method: "put",
    url: `/api/message/update`,
    data
  });
};

export const updateSystemMessage = data => {
  return http({
    method: "put",
    url: `/api/message/updatesystem`,
    data
  });
};

export const deleteMessage = data => {
  return http({
    method: "delete",
    url: `/api/message/delete`,
    data
  });
};

export const userList = params => {
  return http({
    method: "get",
    url: `/api/user/messageuserlist`,
    params
  });
};

export const sysUserList = params => {
  return http({
    method: "get",
    url: `/api/user/sysmessageuserlist`,
    params
  });
};

export const uploadImage = formData => {
  return http({
    url: "/api/message/uploadfile",
    method: "POST",
    data: formData
  });
};

export const sysSendMessageUserList = params => {
  return http({
    method: "get",
    url: `/api/message/syssendmessageuserlist`,
    params
  });
};

export const getSendMessageType = params => {
  return http({
    method: "get",
    url: `api/tenant/messagetypesforcreateorupdate`,
    params
  });
};

export const getReceiveMessageType = params => {
  return http({
    method: "get",
    url: `api/tenant/messagetypesforquery`,
    params
  });
};

export const getSysSendMessageType = params => {
  return http({
    method: "get",
    url: `api/tenant/sysmessagetypes`,
    params
  });
};

export const postSysMessageType = data => {
  return http({
    method: "post",
    url: `api/tenant/createmessagetype`,
    data
  });
};

export const putSysMessageType = data => {
  return http({
    method: "put",
    url: `api/tenant/updatemessagetype`,
    data
  });
};

export const deleteSysMessageType = data => {
  return http({
    method: "delete",
    url: `api/tenant/deletemessagetype`,
    data
  });
};

export const getImportantMessage = params => {
  return http({
    method: "get",
    url: `api/message/systemimportantmessagelist`,
    params
  });
};

export const getNormalMessage = params => {
  return http({
    method: "get",
    url: `api/message/systemnormalmessagelist`,
    params
  });
};
