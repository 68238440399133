import http from "../http";

export const chartsData = params => {
  return http({
    method: "get",
    url: `/api/trade/tradestatistics`,
    params
  });
};
export const storeData = params => {
  return http({
    method: "get",
    url: `/api/trade/shopamountstatistics`,
    params
  });
};

export const payData = params => {
  return http({
    method: "get",
    url: `/api/trade/paycategoryamountstatistics`,
    params
  });
};
